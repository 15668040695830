import request from "./request.js";
import baseUrl from "./config.js"
// import qs from "qs";

// 示例
// export function login(params) {
// 	// let formData = new FormData(); // 创建form对象
// 	// formData.append('presentFormInfoVO', params);
// 	return request({
// 		url: baseUrl + "/spider/web/login",
// 		method: "post",
// 		data: params
// 	});
// 	//其实，也不一定就是params，也可以是 query 还有 data 的呀！
// 	//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
// 	//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。
// }

// 登录
export function login(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/login`,
		method: "post",
		data: params
	});
}

// 设置初始密码
export function initPassword(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/initPassword`,
		method: "post",
		data: params
	});
}

// 获取项目信息
export function getProjectById(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getProjectById`,
		method: "get",
		params: params
	});
}

// 删除会议
export function delMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/delMeeting`,
		method: "post",
		data: params
	});
}

// 发送手机验证码
export function sendCode(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/sendCode/${params}`,
		method: "get",
		// params: params
	});
}

// 我的项目 (助教)
export function projectList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/projectList`,
		method: "get",
		params: params
	});
}
// 我的项目 (医生)
export function dprojectList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/projectList`,
		method: "get",
		params: params
	});
}

export function getProjectList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getProjectList`,
		method: "get",
		params: params
	});
}

// 助教查询医生参与项目列表s
export function doctorProjectList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/doctorProjectList`,
		method: "get",
		params: params
	});
}

// 助教获取今日项目
export function todayMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/todayMeeting`,
		method: "get",
		params: params
	});
}

// 助教获取进入会议的链接
export function getMeetingUrl(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMeetingUrl`,
		method: "get",
		params: params
	});
}

// 新注册用户审核
export function examineUser(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/examineUser`,
		method: "post",
		data: params
	});
}

// 查询会后待上传数量
export function uploadMeetingNum(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getUploadMeetingNum`,
		method: "get",
		params: params
	});
}

// 获取会议列表
export function meetList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/meetingList`,
		method: "get",
		params: params
	});
}

// 上传会后文件  
export function uploadMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/uploadMeetingFile`,
		method: "post",
		data: params
	});
}

// 获取待审核医生列表   waitDoctors
export function waitDoctors(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/waitDoctors`,
		method: "get",
		params: params
	});
}

// 删除会后上传文件  
export function delMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/delMeetingFile`,
		method: "get",
		params: params
	});
}

// 会议上传资料回显
export function getMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMeetingFile`,
		method: "get",
		params: params
	});
}

// 医生端 获取会议列表 
export function doctorMeetList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/meetingList`,
		method: "get",
		params: params
	});
}

// 医生端 今日会议  
export function doctorTodayMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/todayMeeting`,
		method: "get",
		params: params
	});
}

// 获取待签署协议
export function getAgreementNum(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getAgreementNum`,
		method: "get",
		params: params
	});
}

// 进会前签到   
export function signIn(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/signIn`,
		method: "post",
		data: params
	});
}

// 手动结束会议
export function meetingOver(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/meetingOver`,
		method: "post",
		data: params
	});
}

// 医生获取会议链接
export function doctorGetMeetingUrl(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getMeetingUrl`,
		method: "get",
		params: params
	});
}

// 医生获取会议详情 
export function getMeetDetil(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getUpdateMeetingById`,
		method: "get",
		params: params
	});
}

// 助教获取会议详情 
export function getUpdateMeetInfo(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getUpdateMeetingById`,
		method: "get",
		params: params
	});
}

// 医生查询项目角色列表
export function roleList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getMeetingRoleList`,
		method: "get",
		params: params
	});
}

// 助教查询项目角色列表 /sign/live/assistant/getProjectRoleList
export function getroleList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getProjectRoleList`,
		method: "get",
		params: params
	});
}

export function getMeetRoleList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMeetingRoleList`,
		method: "get",
		params: params
	});
}

// 获取会议详情
export function meetingMessage(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getMeetingMessage`,
		method: "get",
		params: params
	});
}

// 医生签署协议/sign/live/doctor/startSign
export function startSign(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/startSign`,
		method: "post",
		data: params
	});
}

// 医生调取劳务列表 
export function orderList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/orderList`,
		method: "get",
		params: params
	});
}

// 助教调取劳务列表 
export function orderListAssistant(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/orderList`,
		method: "get",
		params: params
	});
}

//查看会议协议签署情况
export function getAgreementList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/agreementList`,
		method: "get",
		params: params
	});
}

// 获取医生用户信息 
export function getDoctorInfo(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getUserInfo`,
		method: "get",
		params: params
	});
}

// 获取助教用户信息 
export function getAssistantInfo(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getUserInfo`,
		method: "get",
		params: params
	});
}



// 医生参与的项目数量和会议数量 
export function getMyProjectNumAndMeetingNum(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getMyProjectNumAndMeetingNum`,
		method: "get",
		params: params
	});
}

export function getMeetingNum(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMyProjectNumAndMeetingNum`,
		method: "get",
		params: params
	});
}

// 注册医生用户
export function register(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/register`,
		method: "post",
		data: params
	});
}

// 修改密码 
export function changePassword(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/changePassword`,
		method: "post",
		data: params
	});
}
// /sign/live/assistant/changePassword
export function changeAssistantPwd(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/changePassword`,
		method: "post",
		data: params
	});
}

// 注册用户信息
export function information(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/information`,
		method: "post",
		data: params
	});
}

// 助教修改医生信息
export function changeInfobyId(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/information`,
		method: "post",
		data: params
	});
}

// 获取医生列表  
export function doctorList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getDoctorList`,
		method: "get",
		params: params
	});
}

// 根据Id获取医生信息
export function getDoctorById(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getDoctorById`,
		method: "get",
		params: params
	});
}

// 获取签署协议模板列表
export function getTemplate(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getTemplate`,
		method: "get",
		params: params
	});
}

// 发送签属协议的短信  
export function sendText(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/sendText`,
		method: "post",
		data: params
	});
}
// export function sendTextGET(params) {
// 	return request({
// 		url: `${baseUrl}/sign/live/assistant/sendText`,
// 		method: "get",
// 		params: params
// 	});
// }

// 新建会议  
export function createMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/createMeeting`,
		method: "post",
		data: params
	});
}

// 修改会议  
export function updateMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/updateMeeting`,
		method: "post",
		data: params
	});
}

// 上传会议KV与海报  修改会议海报及背景图 
export function updateMeetingImage(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/updateMeetingImage`,
		method: "post",
		data: params
	});
}

// 回显会议详情
export function getMeetingById(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMeetingById`,
		method: "get",
		params: params
	});
}

// 重新发起审核
export function reapply(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/reapply`,
		method: "post",
		data: params
	});
}

// 导入项目用户 
export function importProjectUser(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/importProjectUser`,
		method: "post",
		data: params
	});
}